import { createSlice } from '@reduxjs/toolkit'

import { RegistrationState } from '../@types/forms'
import { getLocalStorageValue, setLocalStorageValue } from '../utils/localStorageHelpers'

const currentRegistrationFormStep = getLocalStorageValue('currentRegistrationFormStep')
const registrationUserData = getLocalStorageValue('registrationUserData')

const initialState = {
  activeFormStep: Number(currentRegistrationFormStep) || 1,
  stepType: '',
  id: registrationUserData?.id || '',
  firstName: registrationUserData?.firstName || '',
  lastName: registrationUserData?.lastName || '',
  company: registrationUserData?.company || '',
  position: registrationUserData?.position || '',
  phone: registrationUserData?.phone || '',
  email: registrationUserData?.email || '',
  code: registrationUserData?.code || '',
  codeIsValid: registrationUserData?.codeIsValid || false,
  resetToken: registrationUserData?.resetToken || '',
  password: registrationUserData?.password || '',
  confirmPassword: registrationUserData?.confirmPassword || ''
} as RegistrationState

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    nextFormStep: (state) => {
      state.activeFormStep += 1
      setLocalStorageValue('currentRegistrationFormStep', state.activeFormStep)
      return
    },
    backFormStep: (state) => {
      state.activeFormStep -= 1
      setLocalStorageValue('currentRegistrationFormStep', state.activeFormStep)
      return
    },
    setFormStep: (state, { payload }) => {
      state.activeFormStep = Number(payload)
      setLocalStorageValue('currentRegistrationFormStep', state.activeFormStep)
      return
    },
    setStepType: (state, { payload }) => {
      state.stepType = payload
      setLocalStorageValue('currentRegistrationFormStep', state.activeFormStep)
      return
    },
    saveStepWithMainData: (state, action) => {
      return { ...state, ...action?.payload }
    },
    saveStepWithPhone: (state, action) => {
      return { ...state, ...action?.payload }
    },
    saveStepWithEmail: (state, action) => {
      return { ...state, ...action?.payload }
    },
    saveStepWithPassword: (state, action) => {
      return { ...state, ...action?.payload }
    },
    saveStepWithConfirmPassword: (state, action) => {
      return { ...state, ...action?.payload }
    },
    saveResetToken: (state, action) => {
      return { ...state, ...action?.payload }
    },
    saveUserId: (state, action) => {
      return { ...state, ...action?.payload }
    },
    setCodeIsValid: (state, action) => {
      state.codeIsValid = action?.payload
      return
    },
    resetRegistrationForm: (state) => {
      state.activeFormStep = 1
      setLocalStorageValue('currentRegistrationFormStep', 1)
      state.id = ''
      state.stepType = ''
      state.firstName = ''
      state.lastName = ''
      state.company = ''
      state.position = ''
      state.phone = ''
      state.email = ''
      state.code = ''
      state.codeIsValid = false
      state.resetToken = ''
      state.password = ''
      state.confirmPassword = ''
      return
    }
  }
})

export const {
  nextFormStep,
  backFormStep,
  setFormStep,
  setStepType,
  saveStepWithMainData,
  saveStepWithPhone,
  saveStepWithEmail,
  saveStepWithPassword,
  saveStepWithConfirmPassword,
  saveUserId,
  setCodeIsValid,
  resetRegistrationForm
} = registrationSlice.actions

export const selectRegistration = (state: { registration: RegistrationState }) => state.registration

export default registrationSlice.reducer
