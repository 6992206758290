import { createSlice } from '@reduxjs/toolkit'

import { UserLoginResponseType } from '../@types/userTypes'
import { getLocalStorageValue } from '../utils/localStorageHelpers'

const userTokensData = getLocalStorageValue('user_tokens')

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    id: '',
    type: '',
    login: '',
    password: '',
    attributes: {
      auth_token: userTokensData?.auth_token || '',
      refresh_token: userTokensData?.refresh_token || ''
    }
  },
  reducers: {
    saveTokens: (state, action) => {
      return { ...state, ...action?.payload }
    },
    saveAuthData: (state, action) => {
      return { ...state, ...action?.payload }
    },
    deleteAuthData: (state) => {
      return { ...state, login: '', password: '' }
    },
    deleteTokens: (state) => {
      state.id = ''
      state.type = ''
      state.login = ''
      state.password = ''
      state.attributes = {
        auth_token: '',
        refresh_token: ''
      }
      return
    }
  }
})

export const { saveTokens, deleteTokens, saveAuthData, deleteAuthData } = loginSlice.actions

export const selectLogin = (state: { login: UserLoginResponseType }) => state.login

export default loginSlice.reducer
