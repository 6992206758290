import { createContext, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { UserDataType } from '../@types/userTypes'
import { ROUTES } from '../constants/routes'
import { deleteTokens, saveTokens } from '../store/loginSlice'
import { saveBillingSelectedDealerId } from '../store/userBillingSlice'
import { deleteUser, saveUser } from '../store/userSlice'
import { removeLocalStorageValue, setLocalStorageValue } from '../utils/localStorageHelpers'

// @ts-ignore:next-line
const AuthContext = createContext()

// TODO: выпилить обязательно после мвп - есть редакс и локал сторейдж для хранения юзеров и токенов и его фукнции н евызовешь нигде кроме компонентов, из хука сделать набор хелперов и не забыть его проапдейти в коде с /refresh_token
// NOTE: запись токенов уже отрефакторил - остался юзер

export const AuthProvider = ({ children }: any) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const login = async (userLoginData: any) => {
    dispatch(saveTokens(userLoginData))
    setLocalStorageValue('user_tokens', {
      auth_token: userLoginData?.attributes?.auth_token,
      refresh_token: userLoginData?.attributes?.refresh_token
    })
    navigate(ROUTES?.dashboard, { replace: true })
  }

  const logout = async () => {
    dispatch(deleteTokens())
    removeLocalStorageValue('user_tokens')
    dispatch(saveBillingSelectedDealerId(null))
    setLocalStorageValue('currentRegistrationFormStep', 1)
    setLocalStorageValue('billingSelectedDealerId', '')
    deleteUserData()
  }

  const logoutFromAppWithRedirect = async () => {
    logout()
    window.location.replace(`${process.env.REACT_APP_TRACKER_HOST}/cp/contact/auth_support/`)
  }

  const setUserData = async (user: UserDataType) => {
    setLocalStorageValue('user', user)
    dispatch(saveUser(user))
  }

  const deleteUserData = async () => {
    removeLocalStorageValue('user')
    dispatch(deleteUser())
  }

  const value = useMemo(
    () => ({
      login,
      logout,
      logoutFromAppWithRedirect,
      setUserData,
      deleteUserData
    }),
    []
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
